<template>
  <div class="spinner" />
</template>

<style lang="scss" scoped>
.spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #e8e8e8;
  border-left-color: #005eb8;
  border-radius: 100%;
  box-sizing: border-box;

  @keyframes hkAsDC {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(359deg);
      -ms-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  -webkit-animation: hkAsDC 0.7s infinite linear;
  animation: hkAsDC 0.7s infinite linear;
}
</style>
