
import MenuItem from "@/models/MenuItem";
import { defineComponent, ref, watch } from "vue";
import { RouteLocationNormalizedLoaded, RouteMeta, useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    return {
      paths: useCrumbs(route),
    };
  },
});

function useCrumbs(route: RouteLocationNormalizedLoaded) {
  const menuItems = ref<MenuItem[]>([]);
  watch(
    () => route.path,
    (newPath) => {
      let pathArray = newPath.split("/");
      pathArray.shift();
      menuItems.value = pathArray.reduce(
        (breadCrumbArray: MenuItem[], path: string, idx: number) => {
          const matched = route.matched[idx];
          if (matched && matched.meta.breadcrumb) {
            const to = breadCrumbArray[idx - 1]
              ? breadCrumbArray[idx - 1].to + "/" + path
              : "/" + path;
            breadCrumbArray.push({
              label: readTitle(matched.meta, route, to) ?? path,
              to: to,
              class:
                idx == pathArray.length - 1
                  ? "disabled text-primary"
                  : "text-muted",
            });
          }

          return breadCrumbArray;
        },
        []
      );
      menuItems.value.unshift({
        label: "Oversigt",
        to: "/",
        class: "disabled text-muted",
      });
    },
    { immediate: true }
  );

  const titles = ref<{ [to: string]: string }>({});

  function readTitle(
    meta: RouteMeta,
    route: RouteLocationNormalizedLoaded,
    to: string
  ) {
    if (titles.value[to] != null) {
      if (isString(meta.breadcrumb)) {
        return meta.breadcrumb;
      }
      return titles.value[to];
    } else if (isString(meta.breadcrumb)) {
      return meta.breadcrumb;
    } else if (isFunction(meta.breadcrumb)) {
      titles.value[to] = meta.breadcrumb(route);
      return meta.breadcrumb(route);
    }
  }

  function isString(
    breadcrumb:
      | string
      | ((route: RouteLocationNormalizedLoaded) => string)
      | undefined
  ): breadcrumb is string {
    return typeof breadcrumb === "string";
  }

  function isFunction(
    breadcrumb:
      | string
      | ((route: RouteLocationNormalizedLoaded) => string)
      | undefined
  ): breadcrumb is (route: RouteLocationNormalizedLoaded) => string {
    return typeof breadcrumb === "function";
  }

  return menuItems;
}
