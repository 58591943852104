import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { h, resolveComponent, defineAsyncComponent, watch } from "vue";
import useUser from "@/composables/useUser";
import useOidc from "@/composables/useOidc";
import useGlobalLoading from "@/composables/useGlobalLoading";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/organizations",
  },
  {
    path: "/me",
    name: "me",
    component: () => import("@/views/Me.vue"),
  },
  {
    path: "/unauthorized",
    name: "unauthorized",
    component: () => import("@/views/NotAuthorized.vue"),
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/organizations",
    component: {
      render: () => h(resolveComponent("router-view")),
    },
    meta: {
      breadcrumb: "Organisationer",
    },
    children: [
      {
        path: "",
        name: "organizations",
        component: () => import("@/views/Organizations.vue"),
        meta: {
          breadcrumb: "Organisationer",
        },
      },
      {
        path: ":organizationId",
        props: true,
        name: "organization",
        component: () => import("@/views/Organization.vue"),
        // component: {
        //   render() {
        //     return renderWithRouterView("Organization.vue", this.$attrs);
        //   },
        // },
        meta: {
          breadcrumb: (route) =>
            route.query.title?.toString() ?? "Vis organisation",
        },
        children: [
          {
            path: "users",
            meta: {
              breadcrumb: "Brugere",
            },
            redirect: (to) => {
              return "/organizations/" + to.params.organizationId.toString();
            },
          },
          {
            path: "users/:id",
            name: "user",
            meta: {
              needsParentInHistory: true,
            },
            props: true,
            component: () => import("@/views/User/User.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/applications",
    meta: {
      breadcrumb: "Applikationer",
      requiresAuth: true,
    },
    component: {
      render: () => h(resolveComponent("router-view")),
    },
    children: [
      {
        path: "",
        name: "applications",
        component: () => import("@/views/Applications.vue"),
      },
      {
        path: ":applicationId",
        name: "application",
        meta: {
          breadcrumb: (route) =>
            route.query.title?.toString() ?? "Vis applikation",
          requiresAuth: true,
        },
        props: true,
        component: () => import("@/views/Application.vue"),
        children: [
          {
            path: "roles/:id",
            name: "role",
            props: true,
            component: () => import("@/views/Role/Role.vue"),
          },
          {
            path: "profiles/:id",
            name: "profile",
            props: true,
            component: () => import("@/views/Profile/Profile.vue"),
          },
        ],
      },
    ],
  },
];

function renderWithRouterView(
  viewName: string,
  attrs: Attr | undefined = undefined
) {
  return [
    h(resolveComponent("router-view")),
    h(
      defineAsyncComponent(() => import(`@/views/${viewName}`)),
      attrs
    ),
  ];
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, top: 0 };
    }
  },
});

const user = useUser();

router.beforeEach(async (to, from, next) => {
  // When oidc has been initialized, and is logged in, we want to ensure that the user is downloaded from the graph before continuing.
  if (useOidc().user.value != null) {
    const promise = new Promise<void>((resolve, reject) => {
      useGlobalLoading().startLoading();
      if (user.user.value == null) {
        watch(
          () => user.user.value,
          (newValue) => {
            if (newValue) resolve();
          },
          { immediate: true }
        );
      } else {
        resolve();
      }
    });

    await promise;
    next();
    useGlobalLoading().stopLoading();
  } else {
    next();
  }
});

router.beforeEach(async (to, from, next) => {
  if (useOidc().user.value == null) {
    // If OIDC is not logged in, we allow all paths, as we do not want to disturb login flow
    next();
  } else if (
    to.meta.isPublic ||
    user.user.value?.isSuperAdmin ||
    (user.user?.value?.isAdmin &&
      to.params.organizationId == user.user.value?.organizationId) ||
    to.path == "/me"
  ) {
    // First check is user is allowed to visit the requested path.
    if (to.path == "/") {
      next();
    } else if (from.path == "/" && to.meta.needsParentInHistory) {
      next(false);
      await router.push({
        name: to.matched[to.matched.length - 2].name,
        params: to.params,
      });
      await router.push({ name: to.name ?? undefined, params: to.params });
    } else if (to.query) {
      next();
    }
  } else {
    // User is not authorized for that path
    next({
      path: "/unauthorized",
    });
  }
});

export default router;
