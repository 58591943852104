import { readonly, ref } from "vue";

const isLoading = ref(false);

export default function () {
  function startLoading() {
    isLoading.value = true;
  }

  function stopLoading() {
    isLoading.value = false;
  }

  return {
    startLoading,
    stopLoading,
    isLoading: readonly(isLoading),
  };
}
