
import { useResult } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { defineComponent, watch } from "vue";
import { useRouter } from "vue-router";
import BreadCrumb from "./components/BreadCrumb.vue";
import NavigationBar from "./components/NavigationBar.vue";
import Splash from "./components/Splash.vue";
import useConfirmService from "./composables/useConfirmService";
import useGlobalLoading from "./composables/useGlobalLoading";
import useOidc from "./composables/useOidc";
import useUser from "./composables/useUser";
import { useGetProfileQuery } from "./graphql/types";

gql`
  query GetProfile {
    userProfile {
      isAdmin
      isSuperAdmin
      name
      userId
      organizationId
    }
  }
`;

export default defineComponent({
  components: { NavigationBar, BreadCrumb, Splash },
  setup() {
    const oidc = useOidc();
    const confirm = useConfirmService();
    const router = useRouter();
    const user = useUser();
    const { result } = useGetProfileQuery(() => ({
      enabled: oidc.user.value != null,
    }));

    watch(
      () => useResult(result).value,
      (newValue) => {
        user.setUser(newValue ?? undefined);
      },
      { immediate: true }
    );

    oidc.onSoonLoggedOut((seconds) => {
      confirm.show(
        null,
        `Du bliver logget ud om ${seconds} sekunder. Hvis du ikke har gemt dine ændringer skal du gøre det nu. Du kan også trykke log ind, for at logge ind igen`,
        "Ja, log ind igen",
        "Nej, vent",
        () => {
          oidc.forceLogin(router.currentRoute.value);
        }
      );
    });

    return {
      isLoading: useGlobalLoading().isLoading,
      user: user.user,
    };
  },
});
