import { useConfirm } from "primevue/useconfirm";

export default function (group: string | undefined = undefined) {
  const confirm = useConfirm();

  function show(
    clickEvent: Event | null,
    message: string,
    acceptLabel: string,
    rejectLabel: string,
    accept: () => void,
    reject: (() => void) | undefined = undefined
  ) {
    confirm.require({
      group: group,
      target: clickEvent?.currentTarget ?? undefined,
      acceptLabel: acceptLabel,
      rejectLabel: rejectLabel,
      message: message,
      acceptClass: "p-button danger ms-2",
      rejectClass: "p-button secondary",
      accept: accept,
      reject: reject,
    });
  }

  return {
    show: show,
  };
}
