import { App } from "vue";

const lang = "da";

export default {
  install: (app: App) => {
    const translateFunction: (key: string) => string = (key: string) => {
      const languageConfig = config[lang];
      return languageConfig[key];
    };
    app.config.globalProperties.$translate = translateFunction;

    app.provide("$translate", translateFunction);
  },
};

const config: { [key: string]: { [key: string]: string } } = {
  da: {
    TextResources_Role: "Rolle",
    TextResources_Roles: "Roller",
    TextResources_Profile: "Profil",
    TextResources_Profiles: "Profiler",
    TextResources_DeleteProfile: "Slet Profil",
    TextResources_ProfileName: "Profilnavn",
    TextResources_Description: "Beskrivelse",
    TextResources_SaveChange: "Gem ændringer",
    TextResources_Add: "Tilføj",
    TextResources_CreateProfile: "Opret profil",
    TextResources_FoundNumberProfile: "Der blev fundet ",
    TextResources_Create: "Opret",
    TextResources_EmptyProfile: "Ingen profiler",
    TextResources_EmptyProfileDescription:
      "Ingen profiler knyttet til denne applikation er blevet oprettet endnu",
    TextResources_ProfileNotHaveRoles: "Denne profil har ingen tildelte roller",
    TextResources_EmptyRole: "Ingen roller",
    TextResources_EmptyRoleDescription:
      "Der er ikke nogle roller tilknyttet denne bruger endnu",
    TextResources_CreatedProfile: "Profil oprettet",
    TextResources_CreatedProfileDescription: "Den har fået ID",
    TextResources_UpdatedProfile: "Profil ændret",
    TextResources_UpdatedProfileDescription: "Dine ændringer er blevet gemt",
    TextResources_DeletedProfile: "Profil slettet",
    TextResources_DeletedProfileDescription: "Profilen er blevet slettet",
    TextResources_SaveChangeWarningQuestion:
      "Du har foretaget ændringer der ikke er gemt, vil du stadig gå tilbage?",
    TextResources_SaveChangeWarningAnswerConfirm: "Ja, gå tilbage",
    TextResources_SaveChangeWarningAnswerCancel: "Afbryd",

    TextResources_DeleteProfileWarningQuestion:
      "Er du sikker på at du vil slette rollen? Denne handling kan ikke fortrydes",
    TextResources_DeleteProfileWarningnswerConfirm: "Ja, slet",
    TextResources_DeleteProfileWarningAnswerCancel: "Afbryd",
  },
};
